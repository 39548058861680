<template>
  <a-auto-complete v-model="address" :dataSource="options" optionLabelProp="text" @search="(value) => handleSearch(value)" @select="(value, option) => handleSelect(value, option)" dropdownMatchSelectWidth style="width: 100%;" :disabled="disabled">
    <Input slot="search" placeholder="Masukkan kabupaten" />
  </a-auto-complete>
</template>

<script>

import { Input } from 'ant-design-vue';

export default {
  name: 'LokasiAutoComplete',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [], // holds the list of suggestions
      address: '', // holds the selected address
      initial_options: []
    };
  },
  mounted(){
    this.populateAddress()

    this.address = this.value
  },
  methods: {
    // callback function to update the suggestions list based on the user input
    handleSearch(query) {
      // filter the list of options based on the user input
      this.options = this.initial_options.filter(option => option.text.toLowerCase().indexOf(query.toLowerCase()) !== -1);

      // console.log(this.options)
    },
    // callback function to update the selected address when a suggestion is clicked
    handleSelect(value, option) {
      // this.address = value;
      let data = this.initial_options.filter(option => option.value == value);

      this.$emit('select', {
        id: data[0].value,
        name: data[0].text
      })

      this.address = data[0].text
    },
    populateAddress(){
      if(this.$store.state.addresses.length > 0){
        this.initial_options = this.$store.state.addresses

        return 
      }

      this.$http.get(process.env.VUE_APP_API + '/regency')
      .then((res) => {
        this.initial_options = res.data.data

        let result = []

        this.initial_options.map((option, index) => {
          if(!option.hasOwnProperty('value')){
            option.value = option.id.toString()
          }

          if(!option.hasOwnProperty('text')){
            option.text = option.search
          }

          result.push(option)
        })

        this.initial_options = result

        this.$store.dispatch('setAddresses', this.initial_options)
      })
      .catch(err => {
        // this.alert = processMessage(err.response)
        this.initial_options = [
          { value: '', text: 'No address'}
        ]
      })
    }
  },
};
</script>