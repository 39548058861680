<template>

	<!-- Authors Table Card -->
	<div>
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<div class="p-3" v-if="alert.msg">
				<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
			</div>
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="mb-3">
						<h5 class="font-semibold m-0">Tambah Harga Baru</h5>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">

						<a-button @click="onClickBack()" size="small" style="margin-right: 10px;">
							<a-icon type="arrow-left" theme="outlined" />
						    Kembali
						</a-button>
						<a-button type="primary" @click="onSubmitPrice" size="small" :disabled="form.length < 1">
							<a-icon type="plus" theme="outlined" />
						    Simpan
						</a-button>
					</a-col>
				</a-row>
			</template>

			<a-modal v-model="modalBack" title="Kembali" @ok="navigateBack()">
				<div class="mb-3">
		      		<a-alert class="mt-1" type="warning" message="Data harga yang baru dimasukkan di halaman ini akan hilang. Apakah anda yakin?" show-icon></a-alert>
				</div>
		    </a-modal>
			
			<div class="mb-2 p-3">
				<h6 class="font-regular">Tata cara Pengisian: </h6>
				1. Pengisian Kabupaten Asal dan Kabupaten Tujuan <strong>harus di pilih</strong> sesuai dengan yang tersedia. <br/>
				2. Jika Kabupaten yang ingin dipilih tidak ada, maka harus mendaftarkan data Kabupaten di Master Data
			</div>

			<a-row type="flex" class="p-2">
				<div v-for="(item, form_index) in form" :key="`form_${form_index}`" class="mt-3">
					<a-col :span="24" :lg="5" :md="12" :sm="24" class="mb-1">
						<div class="mb-3 mr-1 p-1">
							Pilih Kabupaten Asal
			    			<LokasiAutoComplete @select="(value) => onChangeFromLocation(item, value)" />
		    				<a-alert class="mt-1" type="error" :message="validation[form_index].from_id" show-icon v-if="validation[form_index].from_id"></a-alert>
				    	</div>
					</a-col>
					<a-col :span="24" :lg="5" :md="12" :sm="24" class="mb-1">
						<div class="mb-3 mr-1 p-1">
							Pilih Kabupaten Tujuan
			    			<LokasiAutoComplete @select="(value) => onChangeToLocation(item, value)" />
			    				<a-alert class="mt-1" type="error" :message="validation[form_index].to_id" show-icon v-if="validation[form_index].to_id"></a-alert>
				    	</div>
					</a-col>
					<a-col :span="24" :lg="5" :md="12" :sm="24" class="mb-1">
						<div class="mb-3 mr-1 p-1">
							Pilih Service
							<a-select style="width: 100%" v-model="item.service_id">
							    <a-select-option :value="service.id" v-for="(service, index) in services" :key="`service_option_${index}_${form_index}`">
							        {{service.name}}
							    </a-select-option>
							</a-select>
							<a-alert class="mt-1" type="error" :message="validation[form_index].service_id" show-icon v-if="validation[form_index].service_id"></a-alert>
				    	</div>
					</a-col>
					<a-col :span="24" :lg="5" :md="12" :sm="24" class="mb-1">
						<div class="mb-3 mr-1 p-1">
							Harga<br/>
							<a-input-number :min="1" v-model="item.price" placeholder="Harga" style="width: 100%;" />
							<a-alert class="mt-1" type="error" :message="validation[form_index].price" show-icon v-if="validation[form_index].price"></a-alert>
				    	</div>
					</a-col>
					<a-col :span="24" :lg="4" :md="12" :sm="24" class="mb-1">
						<a-button type="link" danger size="small" class="mt-1" @click="onClickDeleteRow(item)" v-if="form.length > 1">
							<span style="color: red;">
								<a-icon type="close" theme="outlined" /> Hapus #{{form_index + 1}}
							</span>
						</a-button>
					</a-col>
				</div>
				<br/>
				<br/>
				<a-col :span="24" class="mt-3 mb-3">
					<a-button style="width: 100%;" type="primary" @click="onClickCreateNew()">
						<a-icon type="plus" theme="outlined" />
						Tambah Harga
					</a-button>
				</a-col>
			</a-row>
		</a-card>
	</div>
	<!-- / Authors Table Card -->

</template>

<script>

	import { processMessage, setInitialValue } from '../helpers';
	import LokasiAutoComplete from '../components/LokasiAutoComplete';

	export default ({
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
				data: [],
				alert: {
					type: '',
					msg: ''
				},
				form: [
					{
						key: 1,
						from_id: '',
						to_id: '',
						service_id: '',
						price: ''
					}
				],
				new_item: {
					key: 1,
					from_id: '',
					to_id: '',
					service_id: '',
					price: ''
				},
				validation: [
					{
						from_id: '',
						to_id: '',
						service_id: '',
						price: ''
					}
				],
				addresses: [],
				services: [],
				new_key: 1,
				modalBack: false
			}
		},
		components: {
			LokasiAutoComplete
		},
		mounted(){
			this.retrieveServiceList()
		},
	 	methods: {
	 		renderCollapseHeader(prices){
	 			if(prices.prices_count == 0){
	 				return `Dari ${prices.location} - ${prices.name} tidak memiliki harga`
	 			}

	 			return `Dari ${prices.location} - ${prices.name} memiliki ${prices.prices_count} harga`
	 		},
	 		retrieveServiceList(){
	 			this.$http.get(process.env.VUE_APP_API + '/service')
				.then((res) => {
					this.services = res.data.data

					this.retrieveLocationList()
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		},
	 		retrieveLocationList(){
	 			this.$http.get(process.env.VUE_APP_API + '/location')
				.then((res) => {
					this.addresses = res.data.data

					setInitialValue(this.alert)
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		},
	 		navigateBack(){ this.$router.go(-1) },
	 		onClickCreateNew(){ 
	 			this.new_key++
	 			this.new_item.key = this.new_key
	 			this.form.push(JSON.parse(JSON.stringify(this.new_item)))
	 			this.validation.push(JSON.parse(JSON.stringify(this.new_item)))
	 		},
	 		onClickDeleteRow(item){
	 			var index = this.form.indexOf(item)
	 			if(index !== -1){
	 				this.form.splice(index, 1)
	 				this.validation.splice(index, 1)
	 			}
	 		},
	 		onChangeFromLocation(item, value){ item.from_id = value.id },
	 		onChangeToLocation(item, value){ item.to_id = value.id },
	 		onClickBack(){
	 			if(this.is_store){
	 				this.navigateBack();
	 			} else {
	 				this.modalBack = true
	 			}
	 		},
	 		onSubmitPrice(e){
	 			e.preventDefault();

				this.validation.map((validate, index) => {
					setInitialValue(validate)
				})

				setInitialValue(this.alert)

				let check = true;

				this.form.map((item, index) => {
					if(!item.from_id){
						this.validation[index].from_id = 'Pilih kabupaten asal'
						check = false
					}

					if(!item.to_id){
						this.validation[index].to_id = 'Pilih kabupaten tujuan'
						check = false
					}

					if(!item.service_id){
						this.validation[index].service_id = 'Pilih service'
						check = false
					}

					if(!item.price){
						this.validation[index].price = 'Masukkan harga'
						check = false
					}
				})

				if(!check){ return }

	 			this.$http.post(process.env.VUE_APP_API + '/pricing/bulk-store', {
	 				form: this.form
	 			})
				.then((res) => {
					this.alert = processMessage(res)
					this.is_store = true;
					// this.retrieveServiceList()
					setTimeout(() => {
						this.$router.go(-1);
					}, 2000)
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		}
		}
	})

</script>